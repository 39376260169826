.page-loader {
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #333333;
    display: block;
}
.page-loader .fa {
    font-size: 2.5rem;
}
.page-loader,
.page-loader.visible {
    -webkit-transition: 0.2s;
    -ms-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    opacity: 1;
    visibility: visible;
}
.page-loader.hidden {
    visibility: hidden;
    /*    display: none;*/
    opacity: 0;
}
.page-loader div {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: -1rem;
    font-size: 4rem;
}
.page-loader div p {
    margin-top: 1rem;
    font-size: 1rem;
    color: #fff;
    letter-spacing: -0.05rem;
    padding-left: 0.15rem;
    text-transform: uppercase;
    font-family: "OpenSans";
}
